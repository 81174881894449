import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Notification } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "contributing-to-the-design",
      "style": {
        "position": "relative"
      }
    }}>{`Contributing to the design`}<a parentName="h1" {...{
        "href": "#contributing-to-the-design",
        "aria-label": "contributing to the design permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Consistent design of components ensures a great user experience across services. You can contribute to HDS design libraries by proposing new components or additional features and fixes to existing ones.
    </LeadParagraph>
    <p>{`HDS design versions are managed in City of Helsinki Abstract, in a project called  `}<ExternalLink href="https://share.goabstract.com/4be1120f-5c64-4937-9a56-0484b26e20d7" mdxType="ExternalLink">{`Helsinki Design System`}</ExternalLink>{`.`}</p>
    <p>{`The design assets are also available as a design kit package which is downloadable from the official  `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases" mdxType="ExternalLink">{`HDS repository releases`}</ExternalLink>{`.`}</p>
    <h2 {...{
      "id": "proposing-a-design",
      "style": {
        "position": "relative"
      }
    }}>{`Proposing a design`}<a parentName="h2" {...{
        "href": "#proposing-a-design",
        "aria-label": "proposing a design permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`There are two ways of proposing new designs to be added to HDS.`}</p>
    <h3 {...{
      "id": "1-opening-a-new-branch-to-the-abstract-repository",
      "style": {
        "position": "relative"
      }
    }}>{`1. Opening a new branch to the Abstract repository`}<a parentName="h3" {...{
        "href": "#1-opening-a-new-branch-to-the-abstract-repository",
        "aria-label": "1 opening a new branch to the abstract repository permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`If you have access to the `}<em parentName="p">{`City of Helsinki`}</em>{` organisation and `}<em parentName="p">{`Helsinki Design System`}</em>{` project in Abstract follow these steps: `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Create a branch from the HDS Master branch.`}</strong>{` Branch name should follow naming convention `}<em parentName="li">{`Feature request: `}{`[Component name]`}</em>{`. If a related Jira ticket exists, add the number of the ticket at the end of the branch name (for example `}<inlineCode parentName="li">{`Feature request: dropdown-multiselect (HDS-123)`}</inlineCode>{`). `}</li>
      <li parentName="ol"><strong parentName="li">{`Add designs`}</strong>{` related to your proposals to the component or category library file. If a suitable library for the component does not exist yet, add a new library file named `}<inlineCode parentName="li">{`HDS [component name]`}</inlineCode>{` See `}<a parentName="li" {...{
          "href": "#guidelines-for-hds-design-library-files"
        }}>{`Guidelines for HDS design library files`}</a>{` "Guidelines for HDS design library files") bellow for more detailed instructions.`}</li>
      <li parentName="ol"><strong parentName="li">{`When the design is done, make a commit`}</strong>{` along with a description of what changes were made, your component, how should your component be used, and where (give an example layout if possible).`}</li>
      <li parentName="ol"><strong parentName="li">{`Submit branch for review`}</strong>{` and add HDS designers as reviewers. They will provide feedback and suggest changes if needed.`}</li>
    </ol>
    <Notification size="small" label="Design reviews" type="alert" className="siteNotification" mdxType="Notification">
  Do not merge your branch to Master without an approved review. HDS team will run the merge if the feature is accepted.
    </Notification>
    <h3 {...{
      "id": "2-creating-an-issue-to-the-github-repository",
      "style": {
        "position": "relative"
      }
    }}>{`2. Creating an issue to the GitHub repository`}<a parentName="h3" {...{
        "href": "#2-creating-an-issue-to-the-github-repository",
        "aria-label": "2 creating an issue to the github repository permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`If you do not have access to the City of Helsinki Abstract and HDS repository, you can create an issue in  `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system" mdxType="ExternalLink">{`HDS GitHub repository`}</ExternalLink>{`.`}</p>
    <ul>
      <li parentName="ul">{`Use the component name as the issue name and label it as `}<inlineCode parentName="li">{`feature-request`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`When writing the issue, follow the "What & why" issue template. Be specific.`}</li>
      <li parentName="ul">{`You may attach a Sketch file containing the designs to the issue. You can also attach reference screenshots to illustrate your proposal better.`}</li>
    </ul>
    <h2 {...{
      "id": "guidelines-for-hds-design-library-files",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines for HDS design library files`}<a parentName="h2" {...{
        "href": "#guidelines-for-hds-design-library-files",
        "aria-label": "guidelines for hds design library files permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`When designing together, things tend to get messy. Here are some guidelines and tips that help us keep HDS library files clean, consistent, and easy to use for all.`}</p>
    <h3 {...{
      "id": "symbol-naming-and-hierarchy",
      "style": {
        "position": "relative"
      }
    }}>{`Symbol naming and hierarchy`}<a parentName="h3" {...{
        "href": "#symbol-naming-and-hierarchy",
        "aria-label": "symbol naming and hierarchy permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The symbol naming and folder structure of HDS Sketch libraries follow a hierarchy based on `}<em parentName="p">{`CTI (Category-Type-Item)`}</em>{` naming practice.
The CTI taxonomy is a guiding tool for deciding the hierarchy of symbols rather than a strict rule, and it can be adapted if needed.`}</p>
    <p>{`The symbols and layer styles are sorted into folders by descending order of complexity in the following hierarchy: `}</p>
    <blockquote>
      <p parentName="blockquote">{`Category / Type / Item / Sub item / Variants / State`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Category`}</em></strong>{` defines the primitive nature of a property (for example every property in the category `}<inlineCode parentName="li">{`Colour`}</inlineCode>{` is always a colour).`}</li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Type`}</em></strong>{` is a sub-category only applied to property tokens. `}</li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Item`}</em></strong>{` is the actual `}<em parentName="li">{`React component`}</em>{` or `}<em parentName="li">{`Token`}</em>{`. A standalone entity that is meaningful on its own, for example a (`}<inlineCode parentName="li">{`Button`}</inlineCode>{`, `}<inlineCode parentName="li">{`Dropdown`}</inlineCode>{`) or (`}<inlineCode parentName="li">{`Black`}</inlineCode>{`, `}<inlineCode parentName="li">{`White`}</inlineCode>{`). `}</li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Sub-item`}</em></strong>{` are standalone variants of items (for example Button component has sub-items `}<inlineCode parentName="li">{`01 Primary`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Secondary`}</inlineCode>{`, `}<inlineCode parentName="li">{`03 Supplementary`}</inlineCode>{`).`}</li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Variants`}</em></strong>{` are interchangeable symbol versions where some attribute of the item is changed (for example `}<em parentName="li">{`size, layout, colour tint, font weight, breakpoint size, functionality…`}</em>{`). Variants can be for example `}<inlineCode parentName="li">{`400 Regular`}</inlineCode>{`, `}<inlineCode parentName="li">{`500 Medium`}</inlineCode>{`… or . `}<inlineCode parentName="li">{`Left`}</inlineCode>{`, `}<inlineCode parentName="li">{`Right`}</inlineCode>
        <ul parentName="li">
          <li parentName="ul">{`An item can have several levels of variants.`}</li>
          <li parentName="ul">{`Variant levels should preferably be sorted in descending order of scale: `}
            <ul parentName="li">
              <li parentName="ul"><em parentName="li">{`Theme`}</em>{` (light/dark)`}</li>
              <li parentName="ul"><em parentName="li">{`Role`}</em>{` (functionality, feature)`}</li>
              <li parentName="ul"><em parentName="li">{`Breakpoint`}</em>{` (HDS breakpoints)`}</li>
              <li parentName="ul"><em parentName="li">{`Size`}</em>{` (small, medium, large…)`}</li>
              <li parentName="ul"><em parentName="li">{`Shape`}</em>{` (horizontal/vertical)`}</li>
              <li parentName="ul"><em parentName="li">{`Position`}</em>{`/orientation (left/center/right, top/middle/bottom)`}</li>
              <li parentName="ul"><em parentName="li">{`Content`}</em>{` (parts) `}</li>
              <li parentName="ul"><em parentName="li">{`Layout`}</em>{` (alignment and position of content/parts) `}</li>
              <li parentName="ul"><em parentName="li">{`Appearance`}</em>{` (fill, border, status, font weight…)`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`State`}</em></strong>{` is a variant of a component caused by user interaction. For example Hover, Active, Disabled, Open, Closed etc.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: Two levels of `}<em parentName="strong">{`types`}</em>{` in Colour `}<em parentName="strong">{`category`}</em></strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-type.png" alt="Two levels of types in Colour *category" style={{
        "maxWidth": "250px"
      }} viewable mdxType="Image" />
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: The symbol folder structure in `}<em parentName="strong">{`HDS Button`}</em>{`, consists of:`}</strong></p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Item `}<inlineCode parentName="li">{`Buttons`}</inlineCode></li>
        <li parentName="ul">{`Sub-Items `}<inlineCode parentName="li">{`01 Primary`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Secondary`}</inlineCode>{` and `}<inlineCode parentName="li">{`03 Supplementary`}</inlineCode></li>
        <li parentName="ul">{`Variants (1st level) `}<inlineCode parentName="li">{`01 Default`}</inlineCode>{` and `}<inlineCode parentName="li">{`02 Small`}</inlineCode></li>
        <li parentName="ul">{`Variants (2nd level) `}<inlineCode parentName="li">{`01 Text`}</inlineCode>{`, `}<inlineCode parentName="li">{`01 Text + icon left`}</inlineCode>{`…`}</li>
        <li parentName="ul">{`States `}<inlineCode parentName="li">{`01 Enabled`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Hover`}</inlineCode>{`…`}</li>
      </ul>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-folders.png" alt="Example of symbol folder strucuture" style={{
        "maxWidth": "230px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h4 {...{
      "id": "suffixes",
      "style": {
        "position": "relative"
      }
    }}>{`Suffixes`}<a parentName="h4" {...{
        "href": "#suffixes",
        "aria-label": "suffixes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`- modifier`}</em></strong>{` can be used to differentiate same level variants and states of items.`}
        <ul parentName="li">
          <li parentName="ul">{`Modifiers are separated from the variant name with a `}<em parentName="li">{`dash`}</em>{` `}<inlineCode parentName="li">{`-`}</inlineCode>{` (for example `}<inlineCode parentName="li">{`01 Default - 1 row`}</inlineCode>{`, `}<inlineCode parentName="li">{`01 Default - 2 rows`}</inlineCode>{`...).`}</li>
          <li parentName="ul">{`If a modifier adds something to the variant, a `}<em parentName="li">{`plus sign`}</em>{` `}<inlineCode parentName="li">{`+`}</inlineCode>{`  can be used instead (for example `}<inlineCode parentName="li">{`01 Selection`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Selection + text`}</inlineCode>{`…).`}</li>
          <li parentName="ul">{`A modifier should be in `}<em parentName="li">{`all lower case`}</em>{`.`}</li>
          <li parentName="ul">{`If the variant hierarchy is particularly complex, variation levels can be added as modifiers to help differentiate variations in the Sketch symbol menu (for example Body text variant `}<inlineCode parentName="li">{`Body S - Regular - 01 Black - Center`}</inlineCode>{`).`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`(Additional information)`}</em></strong>{` can be added at the end of the name within parentheses `}<inlineCode parentName="li">{`()`}</inlineCode>{`. For example the specific pixel size of a spacing token.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: + modifiers used for additional features`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-modifiers.png" alt="+ modifiers used for additional features" style={{
        "maxWidth": "200px"
      }} viewable mdxType="Image" />
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: additional info about spacing token pixel values`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-additional.png" alt="additional info about spacing token pixel values" style={{
        "maxWidth": "200px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h4 {...{
      "id": "internal-symbol-folders",
      "style": {
        "position": "relative"
      }
    }}>{`Internal symbol folders`}<a parentName="h4" {...{
        "href": "#internal-symbol-folders",
        "aria-label": "internal symbol folders permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`x_Parts`}</em></strong>{` and `}<strong parentName="li"><em parentName="strong">{`x_Sections`}</em></strong>{` are helper symbols used only to enable customising component symbols in Sketch. They are not in themselves usable as components.`}
        <ul parentName="li">
          <li parentName="ul"><em parentName="li">{`Parts`}</em>{` are elements needed for building overrides and larger nested symbols, but are not in standalone items. `}</li>
          <li parentName="ul"><em parentName="li">{`Sections`}</em>{` are larger customisable blocks of complex components that need to be overridable in Sketch.`}</li>
          <li parentName="ul">{`Internal symbols are grouped in `}<inlineCode parentName="li">{`x_Parts`}</inlineCode>{` and `}<inlineCode parentName="li">{`x_Sections`}</inlineCode>{` folders, at the root level of the library.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`x_`}</inlineCode>{` prefix keeps folders at the bottom of the symbol menu.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`xx_Deprecated`}</em></strong>
        <ul parentName="li">
          <li parentName="ul">{`Old versions of items that are going to be replaced in the future but are still supported, are grouped into a folder `}<inlineCode parentName="li">{`xx_Deprecated`}</inlineCode>{` on the root level of the library.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`xx_`}</inlineCode>{` prefix keeps folders at the bottom of the menu, even below `}<inlineCode parentName="li">{`x_Parts`}</inlineCode>{` and `}<inlineCode parentName="li">{`x_Sections`}</inlineCode>{`.`}</li>
        </ul>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: `}<em parentName="strong">{`x_Parts`}</em>{`, `}<em parentName="strong">{`x_Sections`}</em>{` and `}<em parentName="strong">{`xx_Deprecated`}</em>{` folders on HDS Footer`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-parts-sections.png" alt="x_Parts, x_Sections and xx_Deprecated folders on HDS Footer" style={{
        "maxWidth": "200px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h4 {...{
      "id": "pages",
      "style": {
        "position": "relative"
      }
    }}>{`Pages`}<a parentName="h4" {...{
        "href": "#pages",
        "aria-label": "pages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`All symbols of one component`}</strong>{` are grouped on `}<inlineCode parentName="li">{`[Component name]`}</inlineCode>{` page.`}
        <ul parentName="li">
          <li parentName="ul">{`Symbols are aligned and organised on the page in clear groupings.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Examples of available variations`}</strong>{` of the component should be presented with `}<inlineCode parentName="li">{`Example`}</inlineCode>{` artboards.`}
        <ul parentName="li">
          <li parentName="ul">{`Example artboard name should state the component or feature introduced (for example `}<inlineCode parentName="li">{`Example - Responsive sizes`}</inlineCode>{`).`}</li>
          <li parentName="ul">{`Single component libraries and example artboards are grouped on the `}<inlineCode parentName="li">{`Examples`}</inlineCode>{` page.`}</li>
          <li parentName="ul">{`In libraries with more than one component, example artboards can be grouped in the `}<inlineCode parentName="li">{`[Component name]`}</inlineCode>{`  page together with component symbols.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Additional instructions`}</strong>{` about the using of Sketch symbols can be documented on `}<inlineCode parentName="li">{`Instructions`}</inlineCode>{` artboards (for example `}<inlineCode parentName="li">{`Instructions - Footer sections`}</inlineCode>{`).`}
        <ul parentName="li">
          <li parentName="ul">{`If the library has instructions, they can be grouped on the same page with examples named Examples & Instructions.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Internal symbols are grouped on x_Parts and x_Sections pages.`}</li>
      <li parentName="ul">{`If the component has many different sections, each section can be grouped into its page (for example `}<inlineCode parentName="li">{`x_Section - Navigation`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`Deprecated symbols are grouped on the xx_Deprecated page. `}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: pages in a single component library `}<em parentName="strong">{`HDS Navigation`}</em></strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-pages-single.png" alt="Page structure of a single component library" style={{
        "maxWidth": "230px"
      }} viewable mdxType="Image" />
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: pages in a multi component library `}<em parentName="strong">{`HDS Form fields`}</em></strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-pages-multi.png" alt="Page structure of a multi component library" style={{
        "maxWidth": "230px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h4 {...{
      "id": "library-file-name",
      "style": {
        "position": "relative"
      }
    }}>{`Library file name`}<a parentName="h4" {...{
        "href": "#library-file-name",
        "aria-label": "library file name permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`HDS `}{`[Library name]`}</em></strong>{` is usually the same as the `}<strong parentName="li"><em parentName="strong">{`Item`}</em>{` name of the`}</strong>{` in singular form (for example `}<inlineCode parentName="li">{`HDS Button`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`If several items are grouped in one library, the `}<strong parentName="li"><em parentName="strong">{`Category`}</em>{` of the items`}</strong>{` is used instead (for example `}<inlineCode parentName="li">{`HDS Typography`}</inlineCode>{`, `}<inlineCode parentName="li">{`HDS Form Components`}</inlineCode>{`).`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: HDS library file names`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-libraryname.png" alt="Example of HDS library file names" style={{
        "maxWidth": "250px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h2 {...{
      "id": "symbol-naming-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Symbol naming practices`}<a parentName="h2" {...{
        "href": "#symbol-naming-practices",
        "aria-label": "symbol naming practices permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Naming language`}</strong>{` is British English (except for colour names that follow the brand guidelines).`}</li>
      <li parentName="ul">{`Naming should be consistent in design, implementation, and documentation.`}</li>
      <li parentName="ul"><strong parentName="li">{`Taxonomic levels`}</strong>{` are separated with a forward slash `}<inlineCode parentName="li">{`/`}</inlineCode>{` to make a folder.`}
        <ul parentName="li">
          <li parentName="ul">{`No spaces are used around the forward-slash `}<inlineCode parentName="li">{`/`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Start folder names with a `}<em parentName="li">{`capital letter`}</em>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Emoji prefixes`}</strong>{` and `}<em parentName="li">{`all caps`}</em>{` can be used in layer names to make the hierarchy of complex override menus clearer:`}
        <ul parentName="li">
          <li parentName="ul">{`❇️ `}<strong parentName="li">{`[SECTION NAME]`}</strong>{` can be used to highlight `}<em parentName="li">{`sections`}</em>{` (for example `}<inlineCode parentName="li">{`❇️NAVIGATION`}</inlineCode>{`).`}</li>
          <li parentName="ul">{`🔹`}<strong parentName="li">{`[Item]`}</strong>{` can be used to highlight `}<em parentName="li">{`overridable items`}</em>{`. The name can also be in `}<em parentName="li">{`all caps`}</em>{` to make it more distinct (for example `}<inlineCode parentName="li">{`🔹Base - link`}</inlineCode>{`, `}<inlineCode parentName="li">{`🔹DIVIDER`}</inlineCode>{`).`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Sketch smart layout hacks`}</strong>{` may demand extraneous groups to work. In these situations, mark `}<inlineCode parentName="li">{`(smart layout)`}</inlineCode>{` in the name of the group.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: Emoji prefixes in `}<em parentName="strong">{`HDS Footer`}</em>{` layer names`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-layer-prefix.png" alt="Emoji prefixes in HDS Footer layer names" style={{
        "maxWidth": "230px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h3 {...{
      "id": "naming-variant-types",
      "style": {
        "position": "relative"
      }
    }}>{`Naming variant types`}<a parentName="h3" {...{
        "href": "#naming-variant-types",
        "aria-label": "naming variant types permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The basic form`}</strong>{` of the component can be named `}<inlineCode parentName="li">{`01 Basic`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Recommended`}</strong>{` variant can be named `}<inlineCode parentName="li">{`01 Default`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Size`}</strong>{` variants are named with `}<em parentName="li">{`T-shirt sizes`}</em>{` `}<inlineCode parentName="li">{`S`}</inlineCode>{`, `}<inlineCode parentName="li">{`M`}</inlineCode>{`, `}<inlineCode parentName="li">{`L`}</inlineCode>{`, `}<inlineCode parentName="li">{`XL`}</inlineCode>{`, `}<inlineCode parentName="li">{`2XL`}</inlineCode>{`…).`}</li>
      <li parentName="ul"><strong parentName="li">{`Colour theme`}</strong>{` variants are named `}<inlineCode parentName="li">{`01 light`}</inlineCode>{` and `}<inlineCode parentName="li">{`02 Dark`}</inlineCode>{` according to their background colour. `}</li>
      <li parentName="ul"><strong parentName="li">{`Breakpoints`}</strong>{` are always named in the same way:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`01 XL Desktop (≥ 1248)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`02 L Desktop (≥ 992)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`03 M Tablet (≥ 768)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`04 S Mobile (≥ 576)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`05 XS Mobile (≥ 320)`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: Colour theme and breakpoint variants in HDS Footer`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-variants.png" alt="Colour theme and breakpoint variants of footer" style={{
        "maxWidth": "230px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h3 {...{
      "id": "sorting-variants",
      "style": {
        "position": "relative"
      }
    }}>{`Sorting variants`}<a parentName="h3" {...{
        "href": "#sorting-variants",
        "aria-label": "sorting variants permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Numbering can be used in front of the name for listing symbols in a specific order.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Running numbering`}</strong>{` with a leading zero is used for general sorting (for example `}<inlineCode parentName="li">{`01 Primary`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Secondary`}</inlineCode>{`, `}<inlineCode parentName="li">{`03 Supplementary`}</inlineCode>{`).`}</li>
      <li parentName="ul"><strong parentName="li">{`Semantic numbering`}</strong>{` can be used to help communicate variants (for example naming font weights by CSS values: `}<inlineCode parentName="li">{`400 Regular`}</inlineCode>{`, `}<inlineCode parentName="li">{`500 Medium`}</inlineCode>{`, `}<inlineCode parentName="li">{`700 Bold`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`A good practice is to order variants from simple to complex (for example `}<inlineCode parentName="li">{`01 Text`}</inlineCode>{`, `}<inlineCode parentName="li">{`02 Text + heading`}</inlineCode>{`…).`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Example: Sorting variants in `}<em parentName="strong">{`HDS Typography`}</em>{` uses custom prefix for alignment`}</strong></p>
      <Image src="/images/getting-started/contributing-design/sketch-symbol-sorting.png" alt="Sorting variants in HDS Typography" style={{
        "maxWidth": "180px"
      }} viewable mdxType="Image" />
    </blockquote>
    <h2 {...{
      "id": "quality-assurance-checklist",
      "style": {
        "position": "relative"
      }
    }}>{`Quality assurance checklist`}<a parentName="h2" {...{
        "href": "#quality-assurance-checklist",
        "aria-label": "quality assurance checklist permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`With the following checklist, you can check your design files before merging them into the HDS master.`}</p>
    <p>{`Besides checking HDS library files, this list can also be applied to any Sketch project for better organisation.`}</p>
    <p>{`This checklist is (in most parts) also available as a  `}<ExternalLink href="https://www.sketch.com/extensions/assistants/" mdxType="ExternalLink">{`Sketch Assistant`}</ExternalLink>{` that automatically runs a preflight check for Sketch files. If you have Sketch installed on your computer, you may click the following link to automatically install the assistant.`}</p>
    <p>{`For more information see the  `}<ExternalLink href="https://github.com/City-of-Helsinki/hds-assistant" mdxType="ExternalLink">{`HDS Assistant Github page`}</ExternalLink>{`. If you have Sketch installed on your computer you can use the following link to automatically install the assistant (the link will not work if you do not have Sketch installed on a Mac computer).`}</p>
    <ExternalLink size="M" href="sketch://add-assistant?url=https://github.com/City-of-Helsinki/hds-assistant/releases/latest/download/hds-assistant.tgz" mdxType="ExternalLink">Add HDS Assistant to your Sketch (requires Sketch and a Mac computer)</ExternalLink>
    <h3 {...{
      "id": "styles",
      "style": {
        "position": "relative"
      }
    }}>{`Styles`}<a parentName="h3" {...{
        "href": "#styles",
        "aria-label": "styles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`Use shared text and layer styles in components`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Text layers`}</strong>{` are styled with `}<inlineCode parentName="li">{`HDS Typography`}</inlineCode>{` text styles. `}</li>
      <li parentName="ul"><strong parentName="li">{`Layer fills and borders`}</strong>{` are styled with `}<inlineCode parentName="li">{`HDS Colour`}</inlineCode>{` shared styles. `}</li>
      <li parentName="ul">{`Don’t use local styles. All styles used in HDS components should be found in HDS libraries.`}</li>
      <li parentName="ul">{`Respect the shared styles. Don’t customise or break the linking to the original style. This can cause trouble if shared styles are updated later on.`}</li>
      <li parentName="ul">{`If the style you need is missing from shared styles propose adding a shared style to the HDS style libraries.`}</li>
    </ul>
    <h3 {...{
      "id": "layout",
      "style": {
        "position": "relative"
      }
    }}>{`Layout`}<a parentName="h3" {...{
        "href": "#layout",
        "aria-label": "layout permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Align elements and artboards to pixel grid`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Use HDS Spacing values for margins and paddings`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`Spacing`}</inlineCode>{` tokens for the component's internal spacing.`}</li>
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`Layout`}</inlineCode>{` tokens for margins between elements in the layout.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "layers-and-groups",
      "style": {
        "position": "relative"
      }
    }}>{`Layers and groups`}<a parentName="h3" {...{
        "href": "#layers-and-groups",
        "aria-label": "layers and groups permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Order layers in a hierarchy that are easy to understand from the symbol menu overrides.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Layer order = order of overrides in the symbol menu.`}</li>
          <li parentName="ul">{`Layer order and hierarchy should follow the layout order.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Give layers and groups names that state their purpose or content`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Avoid automatically generated names like `}<inlineCode parentName="li">{`Copy`}</inlineCode>{`, `}<inlineCode parentName="li">{`Group`}</inlineCode>{` … `}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Group layers that belong together`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Delete hidden layers or groups`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Delete loose layers or groups that are outside artboards`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Delete empty groups`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Ungroup unnecessary groups`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Groups should have at least two items in them.`}</li>
          <li parentName="ul">{`Avoid groups that have only one another group in them.`}</li>
          <li parentName="ul"><strong parentName="li">{`Note!`}</strong>{` Sketch smart layout hacks may demand extraneous groups to work. In these situations, mark `}<inlineCode parentName="li">{`(smart layout)`}</inlineCode>{` in the name of the group.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "symbols-and-overrides",
      "style": {
        "position": "relative"
      }
    }}>{`Symbols and overrides`}<a parentName="h3" {...{
        "href": "#symbols-and-overrides",
        "aria-label": "symbols and overrides permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Follow the HDS guidelines for symbol names and folder hierarchy`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Variant names are easy to understand from the symbol menu and overrides.`}</li>
          <li parentName="ul">{`Interchangeable variants are grouped so that are easy to swap from the symbol menu and override.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Avoid designing custom parts for components`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Always check if there’s an existing HDS component to answer your need, before making a custom part.`}</li>
          <li parentName="ul">{`If custom parts are needed for example to make customisable nested symbols, group them in the x_Parts folder. See naming conventions for more detail. `}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Overridable text fields default value states the fields purpose`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Hide unnecessary overrides`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`Check that the symbol Smart layout works, and allows all the customisation needed`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Margins and paddings are fixed.`}</li>
          <li parentName="ul">{`Height and width of elements that should not scale are fixed. For example Icons.`}</li>
          <li parentName="ul">{`Text fields scale inappropriate directions when the symbol is scaled.`}</li>
          <li parentName="ul">{`Text fields scale inappropriate directions when content is customised.`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      